import { action, computed, observable } from 'mobx'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { asyncAction } from 'mobx-utils'
import { walletStore } from '@/stores/wallet-store'
import { PostStore } from '@/stores/post-store'
import { appProvider } from '@/app-provider'
import { TagModel } from '@/models/post-model'

export class TrendingViewmodel {
  @observable posts: PostStore[] = []
  @observable fetching = false
  @observable tag: TagModel = {}
  @observable loadingMore = false
  @observable loadState = { _limit: 9, _start: 0 }
  @observable total = 0
  // private _disposers: IReactionDisposer[]

  constructor() {
    //
  }

  destroy() {
    this.posts.forEach((postStore) => postStore.destroy())
  }

  @asyncAction *fetchTags() {
    try {
      this.fetching = true
      const tagId = appProvider.router.currentRoute.params.tagId
      this.tag = yield apiService.tags.findOne(tagId)
      const posts = yield apiService.posts.fetchPosts({ tags: tagId, _limit: 9 })
      this.changeLoadState({ tags: tagId, _limit: 9, _start: 0 })
      yield this.fetchTotalPost({ tags: tagId })
      this.posts = posts.map((item) => new PostStore(item))
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }

  @action.bound changeLoadState(filter) {
    this.loadState = { ...this.loadState, ...filter }
  }

  @asyncAction *fetchTotalPost(filter) {
    try {
      this.total = yield apiService.posts.count({ ...filter, status: 'public' })
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *followTag(tag: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      tag.loading = true
      yield apiService.tagFollows.followTag({ tag: tag })
      tag.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      tag.loading = false
    }
  }

  @asyncAction *unFollowTag(tag: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      tag.loading = true
      yield apiService.tagFollows.unFollowTag({ tag: tag })
      tag.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      tag.loading = false
    }
  }

  get userProfileId() {
    if (walletStore.userInfo) return walletStore.userInfo.profile?._id
    else return ''
  }

  @computed get canLoadMorePost() {
    if (this.posts.length < this.total) return true
    else return false
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      this.loadState = { ...this.loadState, _start: this.loadState._start + this.loadState._limit }
      const postMore = yield apiService.posts.fetchPosts({ ...this.loadState })
      const newPost = postMore.map((post) => new PostStore(post))
      const currentPost = this.posts
      this.posts = [...currentPost, ...newPost]
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMore = false
    }
  }

  @computed get tagContentText() {
    return this.tag.content
  }
}
