





























import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { TrendingViewmodel } from '@/modules/community/home/viewmodels/trending-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'post-list-item': () => import('@/modules/community/common/components/post-list-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class Trending extends Vue {
  @Provide() vm = new TrendingViewmodel()
  walletStore = walletStore

  @Watch('$route.params.tagId', { immediate: true }) tagIdChanged(val: string) {
    this.vm.fetchTags()
  }

  syncFollowState(postStore) {
    const postStoreWithSameOwners = this.vm.posts!.filter((item) => item.post.id === postStore.post.id)
    postStoreWithSameOwners.map((item) => (item.post.isFollowing = postStore.post.isFollowing))
  }

  pushBackward() {
    this.$router.go(-1)
  }

  loadMore(entry) {
    if (this.vm.canLoadMorePost) {
      if (entry.isIntersecting) this.vm.loadMore()
    }
  }
  destroyed() {
    this.vm.destroy()
  }
}
